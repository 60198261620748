import React from 'react';
import Chart from 'chart.js';

class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }
    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.time);
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.update();

        /*this.gradientFill = this.chartRef.createLinearGradient(80, 0, 50, 0);
        this.gradientFill.addColorStop(0, "rgba(104, 131, 187, 0.01)");
        this.gradientFill.addColorStop(1, "rgba(158, 180, 221, 0.9)");*/
    }

    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: 'line',
            options: {
                scales: {
                    xAxes: [{
                        /*type: 'time',
                        time: {
                            unit: 'week'
                        }*/
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: false //this will remove only the label
                        }
                    }
                    ],
                    yAxes: [{
                            ticks: {
                                min: 0,
                                display: true
                            },
                            gridLines: {
                                drawBorder: true,
                                display: false,
                            },
                        }
                    ]
                },
                elements: {
                    line: {
                        tension: 0 // disables bezier curves
                    }
                },
                legend:{
                    display: true
                }
            },
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: [{
                    label: this.props.title,
                    /*labels: this.props.data.map(d => d.label),*/
                    data: this.props.data.map(d => d.value),
                    fill: true,
                    backgroundColor: this.props.bgcolor,
                    /*backgroundColor: this.gradientFill,*/
                    pointRadius: 4,
                    borderColor: this.props.color,
                    borderWidth: 1,
                    lineTension: 0,

                    /*fillColor : "#39b450",*/
                    fillColor : "rgba(172,194,132,1)",
                    /*fillColor : this.gradientFill,*/
                    strokeColor : "#ACC26D",
                    pointColor : "#A6C839",
                    /*pointColor : "#39b450",*/
                    pointStrokeColor : "#A6C839"
                }]
            }
        });
    }

    render() {
        return <canvas ref={this.chartRef} width="100%" height={this.props.height} />;
    }
}

export default LineChart;
