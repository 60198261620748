import React from 'react';
import Home from './components/Home';
import SignIn from './components/SignIn';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Opportunities from './components/Opportunities';
import Projects from './components/Projects';
import MyEsarafuWallet from './components/MyEsarafuWallet';
import NoMatch from './components/NoMatch';
import {Route, Switch} from "react-router-dom";
import logo from './assets/img/logo.png';
import { AsyncStorage } from 'AsyncStorage';
import {connect} from 'react-redux';
import {CONFIG} from './config/default';
import axios from 'axios';
import {signInAction} from './actions';
import Zoom from 'react-reveal/Zoom';

import MyTransections from './components/MyTransections';
import TransferMoney from "./components/TransferMoney";
import Withdraw from './components/Withdraw';
import UserProfile from './components/UserProfile';

class App extends React.Component{
    constructor(props){
        super(props);
        //this._bootstrapAsync();
        this.state = {
            loaded: true
        };
    }
    componentDidMount(){

    }
    render() {
      return (this.state.loaded?
          <Switch>
              <Route component={SignIn} exact path={`/`} />
              <Route component={Home} exact path={`/home`} />
              <Route component={Dashboard} exact path={`/dashboard`} />
              <Route component={Opportunities} path={`/opportunities`} />
              <Route component={Projects} path={`/projects`} />
              <Route component={Projects} path={`/projects`} />
              <Route component={MyEsarafuWallet} path={`/esarafu/wallet`} />
              <Route component={MyTransections} path={`/esarafu/transactions`} />
              <Route component={TransferMoney} path={`/esarafu/transfer`} />
              <Route component={Withdraw} path={`/esarafu/withdraw`} />
              <Route component={UserProfile} path={`/dashboard/profile`} />
              <Route component={NoMatch} />
          </Switch>
              : <div className={`loading-div`}>
                  <p><Zoom><img src={logo} alt="App Logo"/></Zoom></p>
              </div>
      );
  }
    _bootstrapAsync = async () => {
        AsyncStorage.getItem('@BearerAccessToken').then((token) => {
            if(token !== undefined){

            }else{
                this.setState({
                    loaded: true
                });
            }
        });
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (id, name, email, token, phone, userData, tasks) => {
            dispatch(signInAction(id, name, email, token, phone, userData, tasks));
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
