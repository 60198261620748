import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';
import ProfileMenu from "../containers/ProfileMenu";
import Breadcrumb from "../containers/Breabcrumb";
import Switch from "react-switch";
import {CONFIG} from "../config/default";
import LineChart from "../containers/LineChart";
import BarChart from "../containers/BarChart";

import Banner from "../containers/Banner";


class MyEsarafuWallet extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    showLogin=(showHide)=>{
      return this.props.onShowLogin(showHide);
    };
    showResetForm = (showHide)=>{
        return this.props.onShowResetForm(showHide);
    };
    goToProfile=()=>{
        this.props.onShowLogin(false);
        this.setState({
            goToProfile: true
        });
    };
    setOpportunity=(status)=>{

    }
    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home container-fluid">
                    <div className={`row`}>
                        <ProfileMenu />
                        <div className={`col-lg-2 col-md-2`}></div>
                        <div className={`col-lg-10 col-md-10`} style={{padding: 0}}>
                            <Header />
                            <Breadcrumb mainPage="e-Sarafu"  currentPage={`Withdraw e-Sarafu`} />
                            <Banner />

                            <div className={`space-b`}>
                                  
                                <div className={`space-b last-space`}>
                                    <div className={`transfer-sec`}>
                                        <h2 style={{marginBottom : '30px'}}><strong>Withdraw e-Sarafu</strong></h2>

                                        <div className={`transfer-info`}>
                                            <div
                                                style={{marginBottom : '0px'}}
                                                className={`custom-input get-involve-input-container`}>
                                                <input type="phone" placeholder="Phone" />
                                            </div>

                                            <div className={`payment-info`}>
                                                <i class="sort-icon fa fa-sort-down"></i>
                                                <div className={`custom-input get-involve-input-container`}>
                                                    <select onChange={e=>{console.log(e.target.value)}}>
                                                        <option value={``}>Select Payment Method</option>
                                                        <option>Phone Number</option>
                                                        <option>Mpesa</option>
                                                        <option>Airtel Money</option>
                                                        <option>MTN Money</option>
                                                        <option>TigoPesa</option>
                                                        <option>Bank Transfer</option>
                                                    </select>
                                                </div>

                                                <div className="if-number">
                                                    <div className={`custom-input get-involve-input-container`}>
                                                        <input type="phone" placeholder="Enter Phone Number" />
                                                    </div>
                                                </div>

                                                <div className="four-digits">
                                                    <div className="row">
                                                        <div className="col-md-4" style={{paddingRight : '0px'}}>
                                                            <span>Enter 4 digit PIN :</span>
                                                        </div>

                                                        <div className="col-md-6" style={{padding : '0px'}}>
                                                            <div className="digits input-sm">
                                                                <input type="text" />
                                                                <input type="text" />
                                                                <input type="text" />
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                            

                                                <p className="p-sm" style={{marginBottom : '15px',marginTop : '25px',fontSize : '15px'}}>
                                                <span 
                                                    style={{color : '#A6C839'}} className="sp">Disclaimer: </span>Transaction charger 20.00</p>

                                                <div className={`get-involve-input-container`}>
                                                    <button>SEND</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

export default connect(mapStateToProps)(MyEsarafuWallet);
