import React from "react";

class UserProfile extends React.Component{
    render = () => {
        return(
            <div className="">
                this is under construction
            </div>
        )
    }
}

export default UserProfile;