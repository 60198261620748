import React from 'react';
import '../assets/css/animate.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import logo from '../assets/img/logo.png';
import menuIcon from '../assets/img/menu.png';
import {connect} from 'react-redux';
import {showLoginFormAction, showMenuAction} from '../actions';
import {Link, Redirect} from "react-router-dom";
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Slide from "react-reveal/Slide";
import LightSpeed from "react-reveal/LightSpeed";
class Header extends React.Component{
    constructor(props){
      super(props);
      this.state={
          loggedOn: this.props.state.auth.isLoggedIn,
          goToProfile: false,
          goHome: false
      };
    };

    render() {
        return (
            <div className={` main-header`}>
                <header>
                    <div className={`row`}>
                        <div className={`col-lg-2 col-md-2`}></div>
                            <div className={`col-lg-10 col-md-10`}>
                                <div className={`search-wrapper`}>
                                    <i className={`fa fa-search`}></i>
                                    <input type={`text`} placeholder={`Search`} />
                                </div>
                                <div className={`profile-right`}>
                                    <a className={`profile-icon`}><i className={`fa fa-bell`}></i></a>
                                    <a className={`profile-icon`}><i className={`fa fa-question-circle`}></i></a>
                                    <a className={`profile-name`}>
                                        <span><img src={require('../assets/img/user.png')} /></span> John Doe

                                        <ul className="user-options">
                                            <li>
                                                <a href="">My Profile</a>
                                            </li>
                                            <li>
                                                <a href="">My e-Sarafu Wallet</a>
                                            </li>
                                            <li>
                                                <a href="">Help and support</a>
                                            </li>
                                            <li>
                                                <a href="/login">About THIG</a>
                                            </li>
                                            <li>
                                                <a href="/login">Log Out</a>
                                            </li>
                                        </ul>
                                    </a>
                                </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

Header.propTypes = {
    isProfilePage: PropTypes.bool
};

const mapStateToProps = (state, ownProps)=>{
    return{
        state
    };
};


export default connect(mapStateToProps)(Header);
