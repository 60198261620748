import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';
import ProfileMenu from "../containers/ProfileMenu";
import Breadcrumbs from "../containers/Breadcrumbs";
import Switch from "react-switch";
import {CONFIG} from "../config/default";
import LineChart from "../containers/LineChart";
import BarChart from "../containers/BarChart";


class Dashboard extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    showLogin=(showHide)=>{
      return this.props.onShowLogin(showHide);
    };
    showResetForm = (showHide)=>{
        return this.props.onShowResetForm(showHide);
    };
    goToProfile=()=>{
        this.props.onShowLogin(false);
        this.setState({
            goToProfile: true
        });
    };
    setOpportunity=(status)=>{

    }
    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home container-fluid">
                    <div className={`row`}>
                        <ProfileMenu />
                        <div className={`col-lg-2 col-md-2`}></div>
                        <div className={`col-lg-10 col-md-10`} style={{padding: 0}}>
                            <Header />
                            <Breadcrumbs currentPage={`Dashboard`} />
                            <div className={`dashboard-boxes`}>
                                <ul>
                                    <li className={`pink-box`}>
                                        <div className={`profile-box-left`}>
                                            <h4>Projects - Turning Point</h4>
                                            <h2>4</h2>
                                            <div className={`box-footer`}>
                                                <div className={`percentage`}><p>+3.14%</p></div>
                                                <div className={`duration`}><p>Since last month</p></div>
                                            </div>
                                        </div>
                                        <div className={`profile-box-right`}>
                                            <img src={require('../assets/img/pink-head.png')} alt={``} />
                                        </div>
                                    </li>
                                    <li className={`blue-box`}>
                                        <div className={`profile-box-left`}>
                                            <h4>Opportunities - i-Hub</h4>
                                            <h2>1</h2>
                                            <div className={`box-footer`}>
                                                <div className={`percentage`}><p>+3.14%</p></div>
                                                <div className={`duration`}><p>Since last month</p></div>
                                            </div>
                                        </div>
                                        <div className={`profile-box-right`}>
                                            <img src={require('../assets/img/blue-head.png')} alt={``} />
                                        </div>
                                    </li>
                                    <li className={`yellow-box`}>
                                        <div className={`profile-box-left`}>
                                            <h4>e-Sarafu Balance</h4>
                                            <h2>560 UGX</h2>
                                            <div className={`box-footer`}>
                                                <div className={`percentage`}><p>+3.14%</p></div>
                                                <div className={`duration`}><p>Since last month</p></div>
                                            </div>
                                        </div>
                                        <div className={`profile-box-right`}>
                                            <img src={require('../assets/img/yellow-head.png')} alt={``} />
                                        </div>
                                    </li>
                                    <li className={`green-box`}>
                                        <div className={`profile-box-left`}>
                                            <h4>My Points - THIG rewards</h4>
                                            <h2>*4,678</h2>
                                            <div className={`box-footer`}>
                                                <div className={`percentage`}><p>+3.14%</p></div>
                                                <div className={`duration`}><p>Since last month</p></div>
                                            </div>
                                        </div>
                                        <div className={`profile-box-right`}>
                                            <img src={require('../assets/img/green-head.png')} alt={``} />
                                        </div>
                                    </li>
                                </ul>
                                <div className={`clearfix`}></div>
                            </div>

                            <div className={`main-dashboard`}>
                                <div className={`row dashboard-first-row`}>
                                    <div className={`col-lg-7 col-md-7`}>
                                        <div className={`dashboard-box`}>
                                            <div className={`dashboard-box-header`}>
                                                <h2><span>Jamii</span> Taxi Cabs</h2>
                                            </div>
                                            <div className={`dashboard-box-body`}>
                                                <div className={`absolute-content right-text`}>
                                                    <p>Co-own this vehicle by buying up to 20 of 90 slots and share its income from Uber, Bolt (Taxify) and special hire services for the next 3 years.</p>
                                                    <a href={`#`}>Read more</a>
                                                </div>
                                                <div className={`cab-image`}>
                                                    <img src={require('../assets/img/yellow-cab.png')} alt={``} />
                                                </div>
                                            </div>
                                            <div className={`dashboard-box-footer`}>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-5 col-md-5`}>
                                        <div className={`dashboard-box`}>
                                            <div className={`dashboard-box-header`}>
                                                <h3><span>Latest</span> Opportunities <a className={'view-more-link'} href={'/opportunities'}>View all</a></h3>
                                            </div>
                                            <div className={`dashboard-box-body`}>
                                                <ul className={`opportunities-list`}>
                                                    {CONFIG.Opportunities.map((opportunity)=>{

                                                        return <li><a><img src={require(`../assets/img/${opportunity.icon}`)} /> {opportunity.name}</a>
                                                            <p>{opportunity.desc}</p>
                                                            <div className={`opportunity-switch`}>
                                                                <Switch className={`switch-button`} height={20} width={48} offColor={`#F6F9FC`} onColor={`#F6F9FC`} offHandleColor={`#D91C5C`} onHandleColor={`#A6C839`} onChange={()=>this.setOpportunity(!opportunity.active)} checked={opportunity.active} />
                                                            </div>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                            <div className={`dashboard-box-footer`}>

                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div className={`row dashboard-second-row`}>
                                    <div className={`col-lg-7 col-md-7`}>
                                        <div className={`dashboard-box`}>
                                            <div className={`dashboard-box-header`}>
                                                <h3 className={`heading-no-border`}><span>User</span> Investments
                                                    <div className={`user-investment-picker`}>
                                                        <ul>
                                                            <li>Active <i className={`fa fa-sort-down`}></i></li>
                                                        </ul>
                                                    </div>
                                                </h3>
                                            </div>
                                            <div className={`dashboard-box-body`}>
                                                <div className={`big-line-chart-wrapper`}>
                                                    <div className={`investment-chart`}>
                                                        <BarChart
                                                            data={CONFIG.BarChartData}
                                                            title='User Investment'
                                                            color="#243e90"
                                                            bgcolor="#172B4D"
                                                            height={70}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`dashboard-box-footer`}>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-5 col-md-5`}>
                                        <div className={`dashboard-box`}>
                                            <div className={`dashboard-box-header`}>
                                                <h3><span>My</span> Applications
                                                    <div className={`my-applications-nav`}>
                                                        <ul>
                                                            <li className={`current`}><a href={'#'}>Active</a></li>
                                                            <li><a href={'#'}>Approved</a></li>
                                                            <li><a href={'#'}>Rejected</a></li>
                                                        </ul>
                                                    </div>
                                                </h3>
                                            </div>
                                            <div className={`dashboard-box-body`}>
                                                <ul className={`opportunities-list applications-list`}>
                                                    {CONFIG.Applications.active.map((active)=>{
                                                        return <li><a><img src={require(`../assets/img/${active.icon}`)} /> {active.amount}
                                                            <span className={`opportunity-date`}>{active.date}</span>
                                                        </a><p>{active.desc}</p></li>
                                                    })}


                                                </ul>
                                            </div>
                                            <div className={`dashboard-box-footer`}>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`row dashboard-third-row`}>
                                    <div className={`col-lg-12 col-md-12`}>
                                        <div className={`dashboard-box`}>
                                            <div className={`dashboard-box-header`}>
                                                <h3 className={`heading-no-border`}><span>Recent</span> Projects  <a className={'view-more-link'} href={'/projects'}>View all</a></h3>
                                            </div>
                                            <div className={`dashboard-box-body`}>
                                                <ul className={`projects-list`}>
                                                    {CONFIG.RecentProjects.map((project)=>{
                                                        return <li>
                                                            <div className={`projects-left`}>
                                                                <div>
                                                                    <h4>{project.name}</h4>
                                                                    <p>{project.desc}</p>
                                                                    <div className={`projects-nav`}>
                                                                        <a href="#"><img src={require('../assets/img/bookmark.svg')} /></a>
                                                                        <a href="#"><img src={require('../assets/img/comment.svg')} /></a>
                                                                        <a href="#"><img src={require('../assets/img/share.svg')} /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`projects-right`}>
                                                                <img src={require(`../assets/img/${project.icon}`)} />
                                                            </div>
                                                        </li>
                                                    })}


                                                </ul>
                                                <div className={`clearfix clear`} style={{height: '20px'}}></div>
                                            </div>
                                            <div className={`dashboard-box-footer`}>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowLogin: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'login'));
        },
        onShowForgotForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'forgot'));
        },
        onShowResetForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'reset'));
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
