import React from 'react';
import Header from '../containers/Header';
import {Redirect, Link} from "react-router-dom";
import {connect} from 'react-redux';
import LineChart from "../containers/LineChart";
import {showLoginFormAction} from "../actions";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

class Profile extends React.Component{
    delivery_status_code;
    constructor(props){
        super(props);
        this.state = {
            testChartData: [
                {"label": "1", "value": 10},
                {"label": "2", "value": 5},
                {"label": "3", "value": 8},
                {"label": "4", "value": 11},
                {"label": "5", "value": 10},
                {"label": "6", "value": 5},
                {"label": "7", "value": 8},
                {"label": "8", "value": 5}
            ],
            testChartData1: [
                {"label": "1", "value": 12},
                {"label": "2", "value": 8},
                {"label": "3", "value": 15},
                {"label": "4", "value": 11},
                {"label": "5", "value": 15},
                {"label": "6", "value": 9},
                {"label": "7", "value": 4},
                {"label": "8", "value": 7}
            ],
            testChartData2: [
                {"label": "1", "value": 19},
                {"label": "2", "value": 23},
                {"label": "3", "value": 10},
                {"label": "4", "value": 6},
                {"label": "5", "value": 7},
                {"label": "6", "value": 16},
                {"label": "7", "value": 8},
                {"label": "8", "value": 10}
            ],
            testChartData3: [
                {"label": "1", "value": 13},
                {"label": "2", "value": 8},
                {"label": "3", "value": 10},
                {"label": "4", "value": 6},
                {"label": "5", "value": 2},
                {"label": "6", "value": 17},
                {"label": "7", "value": 11},
                {"label": "8", "value": 20}
            ],
            currentTab: '1w',

            bigChartData: [
                {"label": "1", "value": 13},
                {"label": "2", "value": 8},
                {"label": "3", "value": 10},
                {"label": "4", "value": 6},
                {"label": "5", "value": 2},
                {"label": "6", "value": 17},
                {"label": "7", "value": 11},
                {"label": "8", "value": 20}
            ],

            day: [
                {"label": "00:00", "value": 9},
                {"label": "1:00", "value": 5},
                {"label": "2:00", "value": 8},
                {"label": "3:00", "value": 6},
                {"label": "4:00", "value": 4},
                {"label": "5:00", "value": 2},
                {"label": "6:00", "value": 4},
                {"label": "7:00", "value": 7},
                {"label": "8:00", "value": 6},
                {"label": "9:00", "value": 5},
                {"label": "10:00", "value": 8},
                {"label": "11:00", "value": 9},
                {"label": "12:00", "value": 5},
                {"label": "13:00", "value": 8},
                {"label": "14:00", "value": 3},
                {"label": "15:00", "value": 7},
                {"label": "16:00", "value": 2},
                {"label": "17:00", "value": 9},
                {"label": "18:00", "value": 7},
                {"label": "19:00", "value": 8},
                {"label": "20:00", "value": 5},
                {"label": "21:00", "value": 3},
                {"label": "22:00", "value": 2},
                {"label": "23:00", "value": 7},
            ],
            week: [
                {"label": "Mon", "value": 10},
                {"label": "Tue", "value": 5},
                {"label": "Wed", "value": 8},
                {"label": "Thu", "value": 11},
                {"label": "Fri", "value": 10},
                {"label": "Sat", "value": 5},
                {"label": "Sun", "value": 8}
            ],
            month: [
                {"label": "1", "value": 19},
                {"label": "2", "value": 23},
                {"label": "3", "value": 10},
                {"label": "4", "value": 6},
                {"label": "5", "value": 7},
                {"label": "6", "value": 16},
                {"label": "7", "value": 8},
                {"label": "8", "value": 10},
                {"label": "9", "value": 10},
                {"label": "10", "value": 10},
                {"label": "11", "value": 10},
                {"label": "12", "value": 10},
                {"label": "13", "value": 10},
                {"label": "14", "value": 10},
                {"label": "15", "value": 10},
                {"label": "16", "value": 10},
                {"label": "17", "value": 10},
                {"label": "18", "value": 10},
                {"label": "19", "value": 10},
                {"label": "20", "value": 10},
                {"label": "21", "value": 10},
                {"label": "22", "value": 10},
                {"label": "23", "value": 10},
                {"label": "24", "value": 10},
                {"label": "25", "value": 10},
                {"label": "26", "value": 10},
                {"label": "27", "value": 10},
                {"label": "28", "value": 10},
                {"label": "29", "value": 10},
                {"label": "30", "value": 10},
            ],
            month3: [
                {"label": "1", "value": 19},
                {"label": "2", "value": 23},
                {"label": "3", "value": 10},
                {"label": "4", "value": 6},
                {"label": "5", "value": 7},
                {"label": "6", "value": 16},
                {"label": "7", "value": 8},
                {"label": "8", "value": 10}
            ],
            month6: [
                {"label": "Apr", "value": 19},
                {"label": "May", "value": 23},
                {"label": "Jun", "value": 10},
                {"label": "Jul", "value": 6},
                {"label": "Aug", "value": 7},
                {"label": "Sept", "value": 16},
            ],
            year: [
                {"label": "Jan", "value": 19},
                {"label": "Feb", "value": 19},
                {"label": "Mar", "value": 19},
                {"label": "Apr", "value": 19},
                {"label": "May", "value": 23},
                {"label": "Jun", "value": 10},
                {"label": "Jul", "value": 6},
                {"label": "Aug", "value": 7},
                {"label": "Sep", "value": 16},
                {"label": "Oct", "value": 16},
                {"label": "Nov", "value": 16},
                {"label": "Dec", "value": 16},
            ],
        };
    }
    componentDidMount() {

    };

    loadData=(data, currentTab)=>{
        this.setState({
            bigChartData: data,
            currentTab: currentTab
        });
    };

    render() {
        return (!this.props.state.auth.isLoggedIn?
            <Redirect to={{
                pathname: '/',
                data: {}
            }}/>
            :
            <div className="login-full-height">
                <Header isProfilePage={true}/>
                <div className="profile-wrapper">
                    {/*<h2>Dashboard</h2>*/}
                    <div className={`dashboard-box-container`}>
                        <ul>
                            <li>
                                <div className={`stats-row`}>
                                    <div className={`stats-col stats-col1`}>
                                        <h3>TOTAL INVESTMENT</h3>
                                        <div className={`stats-row-inside`}>
                                            <div className={`stats-col stats-col3`}>
                                                <p><span>TSH.</span> 240,000</p>
                                            </div>
                                            <div className={`stats-col stats-col4 stock-increment`}>
                                                <p>+12%</p>
                                            </div>
                                            <div className={`clear`}>{null}</div>
                                        </div>
                                    </div>
                                    <div className={`stats-col stats-col2`}>
                                        <LineChart
                                            data={this.state.bigChartData}
                                            title='Records'
                                            color="#243e90"
                                            bgcolor="#a6b0c9"
                                            height={70}
                                        />
                                    </div>
                                    <div className={`clear`}>{null}</div>
                                </div>
                            </li>
                            <li>
                                <div className={`stats-row`}>
                                    <div className={`stats-col stats-col1`}>
                                        <h3>CAB 1 | T223 DFV</h3>
                                        <div className={`stats-row-inside`}>
                                            <div className={`stats-col stats-col3`}>
                                                <p><span>TSH.</span> 100,000</p>
                                            </div>
                                            <div className={`stats-col stats-col4 stock-increment`}>
                                                <p>+12%</p>
                                            </div>
                                            <div className={`clear`}>{null}</div>
                                        </div>
                                    </div>
                                    <div className={`stats-col stats-col2`}>
                                        <LineChart
                                            data={this.state.testChartData1}
                                            title='Records'
                                            color="#243e90"
                                            bgcolor="#a6b0c9"
                                            height={70}
                                        />
                                    </div>
                                    <div className={`clear`}>{null}</div>
                                </div>

                            </li>
                            <li>
                                <div className={`stats-row`}>
                                    <div className={`stats-col stats-col1`}>
                                        <h3>CAB 2 | T672 BJC</h3>
                                        <div className={`stats-row-inside`}>
                                            <div className={`stats-col stats-col3`}>
                                                <p><span>TSH.</span> 500,000</p>
                                            </div>
                                            <div className={`stats-col stats-col4 stock-increment`}>
                                                <p>+12%</p>
                                            </div>
                                            <div className={`clear`}>{null}</div>
                                        </div>
                                    </div>
                                    <div className={`stats-col stats-col2`}>
                                        <LineChart
                                            data={this.state.testChartData2}
                                            title='Records'
                                            color="#243e90"
                                            bgcolor="#a6b0c9"
                                            height={70}
                                        />
                                    </div>
                                    <div className={`clear`}>{null}</div>
                                </div>

                            </li>
                            <li>
                                <div className={`stats-row`}>
                                    <div className={`stats-col stats-col1`}>
                                        <h3>CAB 3 | T556 DRK</h3>
                                        <div className={`stats-row-inside`}>
                                            <div className={`stats-col stats-col3`}>
                                                <p><span>TSH.</span> 90,000</p>
                                            </div>
                                            <div className={`stats-col stats-col4 stock-increment`}>
                                                <p>+12%</p>
                                            </div>
                                            <div className={`clear`}>{null}</div>
                                        </div>
                                    </div>
                                    <div className={`stats-col stats-col2`}>
                                        <LineChart
                                            data={this.state.testChartData3}
                                            title='Records'
                                            color="#243e90"
                                            bgcolor="#a6b0c9"
                                            height={70}
                                        />
                                    </div>
                                    <div className={`clear`}>{null}</div>
                                </div>

                            </li>
                        </ul>
                    </div>
                    <div className={`big-line-chart-wrapper`}>
                        <div className={`big-line-chart`}>
                            <LineChart
                                data={this.state.bigChartData}
                                title='Records'
                                color="#243e90"
                                bgcolor="#a6b0c9"
                                height={60}
                            />
                        </div>
                        <div className={`big-line-chart-menu`}>

                            <ul>
                                <li className={this.state.currentTab === '1d'?'current-tab':null}>
                                    <button onClick={()=>this.loadData(this.state.testChartData, '1d')}>1D</button></li>
                                <li className={this.state.currentTab === '1w'?'current-tab':null}>
                                    <button onClick={()=>this.loadData(this.state.testChartData2, '1w')}>1W</button></li>
                                <li className={this.state.currentTab === '1m'?'current-tab':null}>
                                    <button onClick={()=>this.loadData(this.state.testChartData3, '1m')}>1M</button></li>
                                <li className={this.state.currentTab === '3m'?'current-tab':null}>
                                    <button onClick={()=>this.loadData(this.state.testChartData, '3m')}>3M</button></li>
                                <li className={this.state.currentTab === '6m'?'current-tab':null}>
                                    <button onClick={()=>this.loadData(this.state.testChartData2, '6m')}>6M</button></li>
                                <li className={this.state.currentTab === '1y'?'current-tab':null}>
                                    <button onClick={()=>this.loadData(this.state.testChartData, '1y')}>1Y</button></li>
                            </ul>
                        </div>
                    </div>
                    <div className={`dashboard-box-container2`}>
                        <ul>
                            <li>
                                <i className={`fa fa-plus`}></i>
                                <h3>TOTAL CAB REVENUE</h3>
                                <h4><span>TSH</span>. 500,000</h4>
                            </li>
                            <li>
                                <i className={`fa fa-plus`}></i>
                                <h3>DIVIDEND</h3>
                                <h4><span>TSH</span>. 500,000</h4>
                            </li>
                            <li>
                                <i className={`fa fa-plus`}></i>
                                <h3>TOTAL SHARES OWNED</h3>
                                <h4>500</h4>
                            </li>
                            <li>
                                <i className={`fa fa-plus`}></i>
                                <h3>TOTAL CABS OWNED</h3>
                                <h4>5</h4>
                            </li>
                        </ul>

                    </div>
                </div>



                {this.props.state.auth.showEditProfileForm ?
                    <div className={`popup-wrapper`}>
                        <div className={`popup`}>
                            <div className={`popup-close`}
                                 onClick={() => this.props.showEditProfile(false)}>
                                <i className={`fa fa-times`}>{null}</i>
                            </div>
                            <Slide bottom>
                                <div>
                                    <div className={`profile-container `}>
                                        <Fade bottom>
                                            <div className={`register-fieldset`}>
                                                <label className={`small-label`}>First Name</label>
                                                <input type="text" value={`Wilffred`} placeholder={`Enter first name`} />
                                            </div>
                                            <div className={`register-fieldset`}>
                                                <label className={`small-label`}>Last name</label>
                                                <input type="text" value={`Magotti`} placeholder={`Enter first name`} />
                                            </div>

                                            <div className={`register-fieldset`}>
                                                <label className={`small-label`}>Email Address <span>verified <i className={`fa fa-chevron-right`}></i></span></label>
                                                <input type="email" value={`wmagotti@gmail.com`} placeholder={`Enter first name`} />
                                            </div>
                                            <div className={`register-fieldset`}>
                                                <label className={`small-label`}>Phone <span>verified <i className={`fa fa-chevron-right`}></i></span></label>
                                                <input type="text" value={`0786416910`} placeholder={`Enter phone or email`} />
                                            </div>

                                            <div className={`register-fieldset`}>
                                                <label className={`small-label`}>Password <button className={`change-password-button`}>Change <i className={`fa fa-chevron-right`}></i></button></label>
                                                <input value={`*********`} type="password" placeholder={`Enter Password`} />
                                            </div>

                                        </Fade>
                                        <div className={`register-fieldset-last`}>
                                            <button className={`register-button`} onClick={() =>this.showLogin(true)}>Continue</button>
                                        </div>
                                    </div>


                                </div>
                            </Slide>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowLogin: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'login'));
        },
        onShowForgotForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'forgot'));
        },
        onShowResetForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'reset'));
        },
        showEditProfile: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'edit_profile'));
        },

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);