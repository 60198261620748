import React from 'react';
import {Link} from "react-router-dom";
class Breadcrumbs extends React.Component{
    constructor(props){
      super(props);
    };

    render() {
        return (
            <div className={`breadcrumbs`}>
                <ul>
                    <li><a href={`#`}>{this.props.currentPage}</a></li>
                    <li><Link to={`/dashboard`}><img src={require('../assets/img/home-vector-white.svg')} /> Home
                        <i className={`fa fa-angle-right`}></i>
                    </Link></li>
                    <li><a href={`#`}>{this.props.currentPage}</a></li>
                </ul>
            </div>
        );
    }
}
export default Breadcrumbs;
