export const signInAction = (id, name, email, token, phone, userData, tasks)=> {
    return{
        type: 'LOGIN',
        name: name,
        email: email,
        id: id,
        token: token,
        phone: phone,
        tasks: tasks,
        userData: userData,
    }
};
export const updateTaskAction = (task)=> {
    return{
        type: 'TASK_UPDATE',
        task: task
    }
};
export const logoutAction = ()=> {
    return{
        type: 'LOGOUT',
    }
};
export const showLoginFormAction = (showHide, formType)=> {
    return{
        type: 'SHOW_LOGIN_FORM',
        showHide: showHide,
        formType: formType
    }
};

export const showMenuAction = (showHide)=> {
    return{
        type: 'SHOW_MENU',
        showHide: showHide
    }
};

export const signUpAction = (id, name, email, allData) => {
    return (dispatch) => {
    };
};

export const onNotificationOpenAction = (opt) => {
    return {
        hasNotification: opt,
        type: 'NOTIFICATION_STATUS'
    };
};

export const setLanguage = (language)=>{
    return{
        type: 'CHANGE_LANGUAGE',
        language: language
    }
};