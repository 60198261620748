import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';
import ProfileMenu from "../containers/ProfileMenu";
import Breadcrumb from "../containers/Breabcrumb";
import Switch from "react-switch";
import {CONFIG} from "../config/default";
import LineChart from "../containers/LineChart";
import BarChart from "../containers/BarChart";

import Banner from "../containers/Banner";


class MyEsarafuWallet extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false,
            transactions :  [
                {id : 0,date : '30-11-2019', topUp : '20,000.00', transfer : '10,000.00', withdrawal : '5,000.00',appliedloans : '2,000.00',repayments : '10,000.00'},
                {id : 0,date : '30-11-2019', topUp : '20,000.00', transfer : '10,000.00', withdrawal : '5,000.00',appliedloans : '2,000.00',repayments : '10,000.00'},
                {id : 0,date : '30-11-2019', topUp : '20,000.00', transfer : '10,000.00', withdrawal : '5,000.00',appliedloans : '2,000.00',repayments : '10,000.00'},
                {id : 0,date : '30-11-2019', topUp : '20,000.00', transfer : '10,000.00', withdrawal : '5,000.00',appliedloans : '2,000.00',repayments : '10,000.00'},
                {id : 0,date : '30-11-2019', topUp : '20,000.00', transfer : '10,000.00', withdrawal : '5,000.00',appliedloans : '2,000.00',repayments : '10,000.00'}
            ]
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    render() {
        const {transactions,loaded,status} = this.state;

        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home container-fluid">
                    <div className={`row`}>
                        <ProfileMenu />
                        <div className={`col-lg-2 col-md-2`}></div>
                        <div className={`col-lg-10 col-md-10`} style={{padding: 0}}>
                            <Header />
                            <Breadcrumb mainPage="e-Sarafu"  currentPage={`My Transactions`} />
                            <Banner />

                            <div className={`space-b`}>
                                  
                                <div className={`space-b last-space`} style={{marginTop : '0px'}}>
                                    <div className={`transfer-sec`} style={{width : '100%'}}>
                                        <h2 style={{marginBottom : '30px'}}>
                                            <strong>My Transactions</strong>
                                        </h2>

                                        <table className='trans'>
                                            <thead>
                                                <tr>
                                                    <th className="sized colored">Date</th>
                                                    <th className="sized colored">Top Up</th>
                                                    <th className="sized colored">Transfer </th>
                                                    <th className="sized colored">Withdrawal </th>
                                                    <th className="sized colored">Applied loans</th>
                                                    <th className="sized colored">Repayments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {transactions.map((transaction,key) => {
                                                return(
                                                    <tr key={key}>
                                                        <td className="sized">{transaction.date}</td>
                                                        <td className="sized">{transaction.topUp}</td>
                                                        <td className="sized">{transaction.transfer}</td>
                                                        <td className="sized">{transaction.withdrawal}</td>
                                                        <td className="sized">{transaction.appliedloans}</td>
                                                        <td className="sized">{transaction.repayments}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

export default connect(mapStateToProps)(MyEsarafuWallet);
