import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {signInAction} from '../actions';

import '../assets/css/animate.css';
import '../assets/css/bootstrap.min.css';
/*import '../assets/css/flaticon.css';*/
import '../assets/css/magnific-popup.css';
import '../assets/css/responsive.css';
import '../assets/css/style.css';
import Flip from "react-reveal/Flip";
import logo from "../assets/img/logo.png";
import JamiiBanner from "../containers/JamiiBanner";

class SignIn extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            goToProfile: false,
            termsAccepted: false
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    goToDashboard=()=>{
        return <Redirect to={{pathname: '/dashboard', data: {}}}/>
    };
    render() {
        return (
            <div className="login-table">
                <div className="login-table-cell">
                {/*<Header isProfilePage={false}/>*/}
                    <div className={`container`}>
                        <div className={`login-wrapper`}>
                            <div className={`row`}>
                                <div className={`login-left col-lg-6 col-md-6`}>
                                    <div className={`login-form-container`}>
                                        <Slide bottom>
                                            <div>
                                                <Slide bottom>
                                                    <div className={`login-logo`}>
                                                        <img src={require('../assets/img/thig_logo.png')} alt={`Logo`} />
                                                    </div>
                                                    <h3>Login to your THIG account</h3>
                                                    <p>Your THIG (Transformation Hub Investment Group ) account allows you to participate in our community centered projects (Turning Point, i-Hub and Sarafu)</p>
                                                    <div className={`register-fieldset username-field`}>
                                                        <div className={`phone-field-wrapper`}>
                                                            <div className={`flag-icon`}><img src={require('../assets/img/255.png')} alt={`Logo`} /></div>
                                                            <div className={`arrow-icon`}>
                                                                <i className={`fa fa-sort-down`}></i>
                                                            </div>
                                                            <div className={`number-prefix`}>+255</div>
                                                        </div>
                                                        <input type="text" placeholder={`753 000 000`}/>
                                                    </div>
                                                    <div className={`register-fieldset password-field`}>
                                                        <div className={`phone-field-wrapper`}>
                                                            <div className={`flag-icon`}><img src={require('../assets/img/lock.png')} alt={`Logo`} /></div>
                                                        </div>
                                                        <input type="password" placeholder={`Password`}/>
                                                    </div>
                                                    <p>
                                                        <a style={{float: 'left'}}  href={`#`}><input type={`checkbox`} value={`1`} checked={this.state.termsAccepted} /> I agree to the <span style={{color: 'blue'}}>Terms of use</span></a>
                                                        <a style={{float: 'right'}} onClick={() => this.props.onShowForgotForm(true)}>Forgot password?</a>
                                                    </p>
                                                    <div className={`register-fieldset-last`}>
                                                        <Link to={`/dashboard`} className={`register-button`}>Login</Link>
                                                    </div>
                                                </Slide>
                                            </div>
                                        </Slide>
                                    </div>
                                </div>
                                <div className={`login-right col-lg-6 col-md-6`}>
                                    <JamiiBanner />
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
