export default function isEmail(text){
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
    if(reg.test(text) !== false){
        return true;
    }else{
        return false;
    }
};

export function get_decimals(number){
    if(number !== '') {
        var price_per_km = 1000;
        var base_price = 1000;
        var kilometer_value = 1000; //1000 metres

        number = Math.abs(number);
        number = number/kilometer_value;
        let distance_integer = Math.floor(number),
            decimal = number - distance_integer,
            sub_price = Number(decimal.toFixed(0));

        if(sub_price>=1){
            distance_integer = distance_integer+sub_price;
        }

        return {
            integer: distance_integer,
            decimal: decimal,
            distance: number,
            price_per_km: price_per_km,
            distance_price: format_money(price_per_km*distance_integer,0,'.',','),
            base_price: format_money(base_price,0,'.',','),
            total: format_money(base_price+(distance_integer*price_per_km),0,'.',','),
            decimal_total: sub_price
        }
    }else{
        return {
            integer: 0,
            decimal: 0,
            distance: 0,
            total: 0,
            base_price: 0,
            price_per_km: 0,
            decimal_total: 0,
            distance_price: 0,

        }
    }
}

export function format_money(number) {
    let decPlaces = 0;
    let decSep = '.';
    let thouSep = ',';
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    let sign = number < 0 ? "-" : "";
    let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    let j;
    j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}
