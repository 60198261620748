import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';
import ProfileMenu from "../containers/ProfileMenu";
import Breadcrumbs from "../containers/Breadcrumbs";
import Switch from "react-switch";
import {CONFIG} from "../config/default";
import LineChart from "../containers/LineChart";
import {format_money} from "../lib/functions";


class Opportunities extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false,
            popupVisible: false,
            viewOpportunity: false,
            opportunity: null
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    showLogin=(showHide)=>{
      return this.props.onShowLogin(showHide);
    };
    showResetForm = (showHide)=>{
        return this.props.onShowResetForm(showHide);
    };
    goToProfile=()=>{
        this.props.onShowLogin(false);
        this.setState({
            goToProfile: true
        });
    };

    showPopup=(item)=>{
        this.setState({
            popupVisible: true,
            opportunity: item
        });
    }
    showOpportunity=(item)=>{
        this.setState({
            viewOpportunity: true,
            opportunity: item
        });
    }

    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home container-fluid">

                    {this.state.popupVisible && <div className={`get-involve-table-wrapper`}>
                        <div className={`get-involve-table`}>
                            <div className={`get-involve-table-cell`}>
                                <div className={`get-involve-window`}>
                                    <h3>Get Involved
                                        <div className={`action-buttons`} style={{float: 'right'}}>
                                            <a style={{fontSize: '12px'}} href={'#'}>Add a banner</a>
                                            <a style={{background:'none', color: '#A6C839', fontSize: '12px'}} href={'#'}>Terms and Conditions</a>
                                            <button onClick={()=>this.setState({popupVisible: false})} className={`close-button`}><i className={`fa fa-times`}></i></button>
                                        </div>
                                    </h3>
                                    <div className={`get-involve-opportunity-form`}>
                                        <div className={`slots-overview`}>
                                        <h4>Slots Overview</h4>
                                            <div className={`slot-row`}>
                                                <ul>
                                                    <li>
                                                        <h5>{this.state.opportunity.totalSlot}</h5>
                                                        <p>Total Slots</p>
                                                    </li>
                                                    <li>
                                                        <h5>{this.state.opportunity.remainingSlot}</h5>
                                                        <p>Remaining Slots</p>
                                                    </li>
                                                    <li>
                                                        <h5><input className={`purchase-slot-input`} type={`number`} value={`1`} step={1} /></h5>
                                                        <p>Purchase Slots</p>
                                                    </li>
                                                    <li>
                                                        <h5>{this.state.opportunity.pricePerSlot}</h5>
                                                        <p>Price Per Slot</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={`slot-row2`}>
                                                <p>Amount to invest: <input className={`purchase-slot-input`} type={`number`} value={`50000`} step={10000} /></p>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={`big-line-chart-wrapper`}>
                                                <div className={`investment-chart`}>
                                                    <LineChart
                                                        data={CONFIG.LineChartData}
                                                        title='Project Revenue'
                                                        color="#A6C839"
                                                        bgcolor="transparent"
                                                        height={70}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className={`get-involve-form`}>
                                            <div className={`get-involve-input-container`}>
                                                <select onChange={e=>{console.log(e.target.value)}}>
                                                    <option value={``}>Select Payment Method</option>
                                                </select>
                                            </div>

                                        </div>

                                        <div className={`get-involve-input-container`}>
                                            <button>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {this.state.viewOpportunity && <div className={`get-involve-table-wrapper`}>
                        <div className={`get-involve-table`}>
                            <div className={`get-involve-table-cell`}>
                                <div className={`get-involve-window`}>
                                    <h3>Opportunity View <button onClick={()=>this.setState({viewOpportunity: false})} className={`close-button`}><i className={`fa fa-times`}></i></button></h3>
                                    <div className={`project-view-content`}>
                                        <h5>Project Name</h5>
                                        <h4>{this.state.opportunity.name}</h4>
                                        <p>{this.state.opportunity.desc}</p>
                                        <p className={`project-image-header`}><img src={require(`../assets/img/${this.state.opportunity.image}`)} /></p>
                                        <h3>Investment Details</h3>
                                        <table className={`ssj-table`}>
                                            <thead>
                                            <tr>
                                                <th style={{width: '25%'}}>Project #</th>
                                                <th style={{width: '25%'}}>Price/Slot</th>
                                                <th style={{width: '25%'}}>Rol</th>
                                                <th style={{width: '25%'}}>Slots Taken</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.opportunity.id}</td>
                                                    <td className={`align-right`}>{format_money(this.state.opportunity.pricePerSlot)}</td>
                                                    <td className={`align-right`}>{this.state.opportunity.roi}</td>
                                                    <td className={`align-right`}>{this.state.opportunity.slotTaken}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className={`row`}>
                        <ProfileMenu />
                        <div className={`col-lg-2 col-md-2`}></div>
                        <div className={`col-lg-10 col-md-10`} style={{padding: 0}}>
                            <Header />
                            <Breadcrumbs currentPage={`Opportunities`} />
                            <div className={`main-content`}>
                                <div className={`table-options`}>
                                    <ul>
                                        <li>Show</li>
                                        <li>
                                            <div>10 <img src={require('../assets/img/arrow-down.svg')} /></div>
                                        </li>
                                        <li>entries</li>
                                    </ul>
                                </div>
                                <table className={`ssj-table`}>
                                    <thead>
                                    <tr>
                                        <th style={{width: '10%'}}>S/No. <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '10%'}}>Deal <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '20%'}}>Description <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '10%'}}>Slot Limit <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '10%'}}>Price/Slot <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '10%'}}>Rol <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '10%'}}>Slots Taken <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                        <th style={{width: '20%'}}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {CONFIG.AllOpportunities.map((opportunity)=> {
                                        return <tr>
                                            <td>{opportunity.id}</td>
                                            <td>{opportunity.name}</td>
                                            <td>{opportunity.desc}</td>
                                            <td className={`align-right`}>{opportunity.slotLimit}</td>
                                            <td className={`align-right`}>{format_money(opportunity.pricePerSlot)}</td>
                                            <td className={`align-right`}>{opportunity.roi}</td>
                                            <td className={`align-right`}>{opportunity.slotTaken}</td>
                                            <td className={`action-buttons`}>
                                                <a onClick={()=>this.showPopup(opportunity)} href={`#`}>Get Involved</a>
                                                <a onClick={()=>this.showOpportunity(opportunity)} href={`#`}>View</a>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowLogin: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'login'));
        },
        onShowForgotForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'forgot'));
        },
        onShowResetForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'reset'));
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Opportunities);
