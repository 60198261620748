import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';
import ProfileMenu from "../containers/ProfileMenu";
import Breadcrumbs from "../containers/Breadcrumbs";
import Switch from "react-switch";
import {CONFIG} from "../config/default";
import LineChart from "../containers/LineChart";
import BarChart from "../containers/BarChart";

import Banner from "../containers/Banner";


class MyEsarafuWallet extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    showLogin=(showHide)=>{
      return this.props.onShowLogin(showHide);
    };
    showResetForm = (showHide)=>{
        return this.props.onShowResetForm(showHide);
    };
    goToProfile=()=>{
        this.props.onShowLogin(false);
        this.setState({
            goToProfile: true
        });
    };
    setOpportunity=(status)=>{

    }
    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home container-fluid">
                    <div className={`row`}>
                        <ProfileMenu />
                        <div className={`col-lg-2 col-md-2`}></div>
                        <div className={`col-lg-10 col-md-10`} style={{padding: 0}}>
                            <Header />
                            <Breadcrumbs currentPage={`My e-Sarafu Wallet`} />
                            <Banner />

                            <div className={`space-b`}>
                                <div className='row'>
                                    <div className={`col-md-4`}>
                                        <div className={`item-box`}>
                                            <div className={`icon-box`}>
                                                <img src={require('../assets/img/wallet-icon.png')} alt="icon" />
                                            </div>

                                            <div className={`item-info`}>
                                                <h2 className={`h-bold`}>0.00</h2>
                                                <p className={`info`}>Balance - e-Sarafu Wallet</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-md-4`}>
                                        <div className={`item-box`}>
                                            <div className={`icon-box`}>
                                                <img src={require('../assets/img/money-icon.png')} alt="icon" />
                                            </div>

                                            <div className={`item-info`}>
                                                <h2 className={`h-bold`}>50,000.00</h2>
                                                <p className={`info`}>Total Available Balance</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-md-4`}>
                                        <div className={`item-box`}>
                                            <div className={`icon-box`}>
                                                <img src={require('../assets/img/transfer-icon.png')} alt="icon" />
                                            </div>

                                            <div className={`item-info`}>
                                                <h2 className={`h-bold`}>2,000.00</h2>
                                                <p className={`info`}>Recent Transactions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`space-b last-space`}>
                                <div className={`top-up`}>
                                    <h4><strong>Top up Account</strong></h4>

                                    <div className={`payment-info`}>
                                        <i class="sort-icon fa fa-sort-down"></i>
                                        <div className={`custom-input get-involve-input-container`}>
                                            <select onChange={e=>{console.log(e.target.value)}}>
                                                <option value={``}>Select Payment Method</option>
                                                <option>Credit/Debit Card</option>
                                                <option>e-Sarafu</option>
                                                <option>TigoPesa</option>
                                                <option>Mpesa</option>
                                                <option>Airtel Money</option>
                                                <option>MTN Money</option>
                                            </select>
                                        </div>

                                        <div className={`get-involve-input-container`}>
                                            <button>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

export default connect(mapStateToProps)(MyEsarafuWallet);
