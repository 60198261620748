import React from 'react';
import cab from '../assets/img/cab.png';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import {Link} from "react-router-dom";
import Flip from "react-reveal/Flip";
import logo from "../assets/img/logo.png";

class JamiiBanner extends React.Component{
    render() {
        return (
            <div className={`header-banner`}>
                <div className={`logo-container`}>
                    <Link to="/">
                        <Flip left>
                            <img src={logo} className="black" alt="App Logo"/>
                        </Flip>
                    </Link>
                </div>

                <div className={`banner-text`}>
                    <Fade bottom>
                        <h5>Buy a share</h5>
                        <h5>in a cab and earn</h5>
                    </Fade>
                    <Zoom><h4>MONTHLY</h4></Zoom>
                </div>
                <RubberBand>
                    <div className={`banner-ballon`}>
                        <Fade right>
                            <img src={cab} className="cab" alt="Cab"/>
                        </Fade>
                        <h5>From<br/>
                            <span>UGX</span></h5>
                        <h4>200,000</h4>
                        <p>Invest in cab<br/>
                            and earn<br/>
                            passive income</p>
                    </div>
                </RubberBand>
            </div>
        );
    }
}

export default JamiiBanner;
