import React from 'react';
import Header from '../containers/Header';
import {Link} from "react-router-dom";

class NoMatch extends React.Component{
    render() {
        return (
            <div className="App">
                <p>404</p>
            </div>
        );
    }
}

export default NoMatch;