import React from 'react';
import Chart from 'chart.js';

class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }
    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.time);
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.update();

        /*this.gradientFill = this.chartRef.createLinearGradient(80, 0, 50, 0);
        this.gradientFill.addColorStop(0, "rgba(104, 131, 187, 0.01)");
        this.gradientFill.addColorStop(1, "rgba(158, 180, 221, 0.9)");*/
    }

    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                            /*type: 'time',
                            time: {
                                unit: 'week'
                            }*/
                            gridLines: {
                                display: false,
                                color: "#FFFFFF"
                            },
                        ticks: {
                            display: true //this will remove only the label
                        },
                        barPercentage: 0.16
                    }
                    ],
                    yAxes: [{
                            ticks: {
                                min: 0,
                                display: true
                            },
                            gridLines: {
                                drawBorder: true,
                                display: true,
                            },
                        }
                    ]
                },
                elements: {
                    line: {
                        tension: 10 // disables bezier curves
                    }
                },
                legend:{
                    display: false
                },
                responsive: true,
                tooltips: {
                    mode: 'label',
                }
            },
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: [{
                    label: this.props.title,
                    data: this.props.data.map(d => d.value),
                    fill: true,
                    backgroundColor: this.props.data.map(d => d.color),
                    borderColor: this.props.color,
                    borderWidth: 0
                }]
            }
        });
    }

    render() {
        return <canvas className={`myBarChart`} ref={this.chartRef} width="100%" height={this.props.height} />;
    }
}

export default BarChart;
