import React,{Component} from "react";

class Banner extends Component{
    render = () => {
        return(
            <div className="yellow-banner">
                <img src={require('../assets/img/banner.png')} alt="banner-img" />
            </div>
        )
    }
}

export default Banner;