const defaultState = {
    isLoggedIn: true,
    name: 'Said',
    id: 1,
    email: 'test@email.com',
    token: null,
    phone: '255753443398',
    language: 'en',
    userData: null,
    tasks: null,
    hasNotification: false,
    showLoginForm: false,
    showForgotForm: false,
    showResetForm: false,
    showEditProfileForm: false,
    showBySharesForm: false,
    showPayment: false,
    showMenu: false
};

export default (state = defaultState, action)=>{
    switch(action.type){
        case 'LOGIN':
            console.log("Writing new state: ", action);
            return Object.assign({}, state,
                {
                    isLoggedIn: true,
                    name: action.name,
                    id: action.id,
                    email: action.email,
                    token: action.token,
                    phone: action.phone,
                    tasks: action.tasks,
                    userData: action.userData
            });
        case 'TASK_UPDATE':
            return Object.assign({}, state,
                {
                    tasks: [...state.tasks, action.task]
            });
        case 'SHOW_LOGIN_FORM':
            if(action.formType === 'login') {
                return Object.assign({}, state,
                    {
                        showLoginForm: action.showHide,
                        showResetForm: false,
                        showForgotForm: false,
                    });
            }else if(action.formType === 'forgot') {
                return Object.assign({}, state,
                    {
                        showForgotForm: action.showHide,
                        showResetForm: false,
                        showLoginForm: false,
                    });
            }else if(action.formType === 'reset') {
                return Object.assign({}, state,
                    {
                        showResetForm: action.showHide,
                        showForgotForm: false,
                        showLoginForm: false,
                    });
            }else if(action.formType === 'edit_profile') {
                return Object.assign({}, state,
                    {
                        showEditProfileForm: action.showHide,
                        showBySharesForm: false,
                        showForgotForm: false,
                        showLoginForm: false,
                        showResetForm: false
                    });
            }else if(action.formType === 'buy_shares') {
                return Object.assign({}, state,
                    {
                        showEditProfileForm: false,
                        showBySharesForm: action.showHide,
                        showForgotForm: false,
                        showLoginForm: false,
                        showResetForm: false
                    });
            }
        // eslint-disable-next-line no-fallthrough
        case 'SHOW_MENU':
            return Object.assign({}, state,
                {
                    showMenu: action.showHide
                });
        case 'REGISTER':
            return state={};
        case 'LOGOUT':
            return state=defaultState;
        case 'NOTIFICATION_STATUS':
            return Object.assign({}, state,
                {
                hasNotification: action.opt
            });
        case 'CHANGE_LANGUAGE':
            return{
                language:action.language
            };
        default:
            return state;
    }
}