export const CONFIG = {
    IPAddress: "",
    ServerURL: "",
    CLIENT_SECRET: "",
    CLIENT_ID: 0,
    categories:[],
    buttonHeight: 40,
    sampleActivities: [],
    emailPrefix: '',
    businessNumber: '',
    MAPS_API_KEY: '',
    MAPS_DIRECTION_API_KEY: '',
    DeliveryType: [
        {"id":"1","name":"Food"},
        {"id":"2","name":"Parcel"},
        {"id":"3","name":"Document"}],
    AccessToken: '',
    Opportunities: [
        {name: 'Jamii', desc: 'Second jamii cab', icon: 'green-head.png', active: false},
        {name: 'Jamii', desc: 'Second jamii cab', icon: 'green-head.png', active: true},
        {name: 'Jamii', desc: 'Second jamii cab', icon: 'green-head.png', active: true},
        {name: 'Jamii', desc: 'Second jamii cab', icon: 'green-head.png', active: false},
        {name: 'Jamii', desc: 'Second jamii cab', icon: 'green-head.png', active: true}
    ],
    AllOpportunities: [
        {id: 1, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 2, pricePerSlot: 250000, roi: 1, totalSlot: 30, remainingSlot: 30,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 2, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 6, pricePerSlot: 500000, roi: 1, totalSlot: 22, remainingSlot: 22,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 3, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 3, pricePerSlot: 650000, roi: 1, totalSlot: 20, remainingSlot: 20,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 4, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 10, pricePerSlot: 310000, roi: 1, totalSlot: 15, remainingSlot: 15,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 5, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 6, pricePerSlot: 600000, roi: 1, totalSlot: 50, remainingSlot: 50,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 6, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 4, pricePerSlot: 200000, roi: 1, totalSlot: 230, remainingSlot: 230,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 7, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 3, pricePerSlot: 100000, roi: 1, totalSlot: 40, remainingSlot: 40,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 8, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 9, pricePerSlot: 200000, roi: 1, totalSlot: 7, remainingSlot: 7,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 9, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 15, pricePerSlot: 150000, roi: 1, totalSlot: 1, remainingSlot: 1,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 10, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 20, pricePerSlot: 850000, roi: 1, totalSlot: 20, remainingSlot: 20,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 11, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 10, pricePerSlot: 500000, roi: 1, totalSlot: 33, remainingSlot: 33,slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},
        {id: 12, name: 'Deal 1', desc: 'Jamii Cab', slotLimit: 5, pricePerSlot: 205000, roi: 1, totalSlot: 18, remainingSlot: 18, slotTaken: 1, icon: 'green-head.png', active: false, image: 'transport.png'},

    ],
    Applications: {
            active: [
                {amount: 5000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 8000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 6000, desc: 'Top up', date: '09 Sept, 2019', icon: 'pink-head.png'},
                {amount: 2000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 9000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'}
                ],
            approved: [
                {amount: 5000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 8000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 6000, desc: 'Top up', date: '09 Sept, 2019', icon: 'pink-head.png'},
                {amount: 2000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 9000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'}
                ],
            rejected: [
                {amount: 5000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 8000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 6000, desc: 'Top up', date: '09 Sept, 2019', icon: 'pink-head.png'},
                {amount: 2000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'},
                {amount: 9000, desc: 'Top up', date: '05 Aug, 2019', icon: 'pink-head.png'}
                ],
        },
    RecentProjects:[
        {id: 1, name: 'Transport', desc: 'Jamii 2', icon: 'transport.png'},
        {id: 2, name: 'Agriculture', desc: 'Jamii 2', icon: 'agriculture.png'},
        {id: 3, name: 'Real Estate', desc: 'Jamii 2', icon: 'real-estate.png'}
    ],
    Projects:[
        {id: 1, name: 'Transport', desc: 'Jamii 2', number: 'DHA0065874', icon: 'transport.png'},
        {id: 2, name: 'Agriculture', desc: 'Jamii 2', number: 'DHA0065874', icon: 'agriculture.png'},
        {id: 3, name: 'Real Estate', desc: 'Jamii 2', number: 'DHA0065874', icon: 'real-estate.png'},
        {id: 4, name: 'Transport', desc: 'Jamii 2', number: 'DHA0065874', icon: 'transport.png'},
        {id: 5, name: 'Agriculture', desc: 'Jamii 2', number: 'DHA0065874', icon: 'agriculture.png'},
        {id: 6, name: 'Real Estate', desc: 'Jamii 2', number: 'DHA0065874', icon: 'real-estate.png'},
        {id: 7, name: 'Transport', desc: 'Jamii 2', number: 'DHA0065874', icon: 'transport.png'},
        {id: 8, name: 'Agriculture', desc: 'Jamii 2', number: 'DHA0065874', icon: 'agriculture.png'},
        {id: 9, name: 'Real Estate', desc: 'Jamii 2', number: 'DHA0065874', icon: 'real-estate.png'},
        {id: 10, name: 'Transport', desc: 'Jamii 2', number: 'DHA0065874', icon: 'transport.png'},
        {id: 11, name: 'Agriculture', desc: 'Jamii 2', number: 'DHA0065874', icon: 'agriculture.png'},
        {id: 12, name: 'Real Estate', desc: 'Jamii 2', number: 'DHA0065874', icon: 'real-estate.png'},
    ],
    BarChartData: [
        {"label": "Investment", "value": 4, "color":"#0E76BB"},
        {"label": "Income", "value": 3, "color":"#D91C5C"},
        {"label": "Wallet", "value": 5, "color":"#A6C839"}
    ],
    LineChartData: [
        {"label": "1", "value": "1"},
        {"label": "2", "value": "5"},
        {"label": "3", "value": "10"},
        {"label": "4", "value": "12"},
        {"label": "5", "value": "15"},
        {"label": "6", "value": "19"},
        {"label": "7", "value": "21"},
        {"label": "8", "value": "23"},
        {"label": "9", "value": "30"},
        {"label": "10", "value": "33"}
    ]
};
