import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
class ProfileMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            currentMenu: ''
        };
    }
    render() {
        return (<div className={`col-lg-2 col-md-2 profile-menu-wrapper`}>
                    <div className={`menu-logo`}>
                        <img src={require('../assets/img/thig_logo.png')} alt={'Logo'} />
                    </div>
                    <div className={`dashboard-menu`}>
                        <ul>
                            <li><Link to="/dashboard"><img src={require('../assets/img/home-vector.svg')} className={`vector-green`} /> Dashboard
                                </Link></li>
                            <li><Link to="/projects"><img src={require('../assets/img/heart.svg')} className={`vector-green`} /> Turning Point
                                <i className={`fa fa-sort-down`} style={{color:'#A6C839'}}></i>
                            </Link></li>
                            <li><Link to="/opportunities"><img src={require('../assets/img/cogs.svg')} className={`vector-green`} /> i-Hub
                                <i className={`fa fa-sort-down`} style={{color:'#A6C839'}}></i>
                            </Link></li>
                            <li><a href="#" onClick={()=>{
                                this.setState({
                                    currentMenu: this.state.currentMenu === 'esarafu'?'': 'esarafu'
                                 }
                            )}}><img src={require('../assets/img/coins.svg')} className={`vector-green`} /> e-Sarafu
                                <i className={`fa fa-sort-down`} style={{color:'#A6C839'}}></i>
                            </a>
                                {this.state.currentMenu === 'esarafu' &&
                                <ul>
                                    <li><a href={`/esarafu/wallet`}>My e-Sarafu Wallet</a></li>
                                    <li><a href={`/esarafu/transfer`}>Transfer Money</a></li>
                                    <li><a href={`/esarafu/withdraw`}>Withdraw e-Sarafu</a></li>
                                    <li><a href={`/esarafu/loan`}>Get a Loan</a></li>
                                    <li><a href={`/esarafu/transactions`}>My Transactions</a></li>
                                </ul>
                                }
                            </li>
                        </ul>
                    </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};
export default connect(mapStateToProps)(ProfileMenu);
