import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';
import ProfileMenu from "../containers/ProfileMenu";
import Breadcrumbs from "../containers/Breadcrumbs";
import Switch from "react-switch";
import {CONFIG} from "../config/default";
import LineChart from "../containers/LineChart";
import BarChart from "../containers/BarChart";


class Projects extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false,
            popupVisible: false,
            viewProject: false,
            project: null
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    showPopup=(item)=>{
        this.setState({
            popupVisible: true,
            project: item
        });
    }
    showProject=(item)=>{
        this.setState({
            viewProject: true,
            project: item
        });
    }
    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home container-fluid">
                    {this.state.popupVisible && <div className={`get-involve-table-wrapper`}>
                        <div className={`get-involve-table`}>
                            <div className={`get-involve-table-cell`}>
                                <div className={`get-involve-window`}>
                                    <h3>Get Involved <button onClick={()=>this.setState({popupVisible: false})} className={`close-button`}><i className={`fa fa-times`}></i></button></h3>
                                    <div className={`get-involve-form`}>
                                        <div className={`get-involve-input-container`}>
                                            <select onChange={e=>{console.log(e.target.value)}}>
                                                <option value={``}>Enter Your Skill</option>
                                            </select>
                                        </div>
                                        <div className={`get-involve-input-container`}>
                                            <select onChange={e=>{console.log(e.target.value)}}>
                                                <option value={``}>Type of Support</option>
                                            </select>
                                        </div>
                                        <div className={`get-involve-input-container`}>
                                            <input type={`text`} onChange={e=>{console.log(e.target.value)}} value={``} placeholder={`Enter Amount`}/>
                                        </div>
                                        <div className={`get-involve-input-container`}>
                                            <select onChange={e=>{console.log(e.target.value)}}>
                                                <option value={``}>Select Payment Method</option>
                                            </select>
                                        </div>
                                        <div className={`get-involve-input-container`}>
                                            <button>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {this.state.viewProject && <div className={`get-involve-table-wrapper`}>
                        <div className={`get-involve-table`}>
                            <div className={`get-involve-table-cell`}>
                                <div className={`get-involve-window`}>
                                    <h3>Project View <button onClick={()=>this.setState({viewProject: false})} className={`close-button`}><i className={`fa fa-times`}></i></button></h3>
                                    <div className={`project-view-content`}>
                                        <h5>Project Name</h5>
                                        <h4>{this.state.project.name}</h4>
                                        <p>{this.state.project.desc}</p>
                                        <p className={`project-image-header`}><img src={require(`../assets/img/${this.state.project.icon}`)} /></p>
                                        <h3>Investment Details</h3>
                                        <table className={`ssj-table`}>
                                            <thead>
                                            <tr>
                                                <th style={{width: '30%'}}>Total Count</th>
                                                <th style={{width: '35%'}}>Type of support</th>
                                                <th style={{width: '35%'}}>Donated Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Lorem Ipsum</td>
                                                <td>50,000</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Ipsum Lorem</td>
                                                <td>100,000</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className={`row`}>
                        <ProfileMenu />
                        <div className={`col-lg-2 col-md-2`}></div>
                        <div className={`col-lg-10 col-md-10`} style={{padding: 0}}>
                            <Header />
                            <Breadcrumbs currentPage={`Projects`} />

                            <div className={`main-content`}>
                                <div className={`table-options`}>
                                    <ul>
                                        <li>Show</li>
                                        <li>
                                            <div>10 <img src={require('../assets/img/arrow-down.svg')} /></div>
                                        </li>
                                        <li>entries</li>
                                    </ul>
                                </div>
                                <table className={`ssj-table`}>
                                    <thead>
                                        <tr>
                                            <th style={{width: '10%'}}>S/No. <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                            <th style={{width: '20%'}}>Project Name <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                            <th style={{width: '30%'}}>Description <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                            <th style={{width: '20%'}}>Project Number <a className={`sorting-button`} href={'#'}><img src={require('../assets/img/sorting.png')} /></a></th>
                                            <th style={{width: '20%'}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {CONFIG.Projects.map((project)=> {
                                        return <tr>
                                                    <td>{project.id}</td>
                                                    <td>{project.name}</td>
                                                    <td>{project.desc}</td>
                                                    <td>{project.number}</td>
                                                    <td className={`action-buttons`}>
                                                        <a onClick={()=>this.showPopup(project)} href={`#`}>Get Involved</a>
                                                        <a onClick={()=>this.showProject(project)} href={`#`}>View</a>
                                                    </td>
                                                </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

export default connect(mapStateToProps)(Projects);
